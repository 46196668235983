import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export default () => (
  <>
    <SEO
      title="Looks like you might be lost |  YCGAMING | Authentic Italian Limoncello"
      description=""
      keywords=""
    />
    <Layout>
      <div class="container p-5">
        <h3>404 - Page not found</h3>
        <h4>We can't find the page you're looking for</h4>
        <ul class="p-1">
          <p>This might be because:</p>
          <li>The link isn’t working properly or the page has been moved</li>
          <li>You’ve typed the page address (URL) incorrectly</li>
        </ul>
        <p>
          Click{" "}
          <Link class="link-color" to="/">
            here
          </Link>{" "}
          to head back to the home page.
        </p>
      </div>
    </Layout>
  </>
);
